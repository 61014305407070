<template>
  <b-container fluid class="bg-white">
    <b-row>
      <img src="@/assets/icon.png" class="w-75 mx-auto my-3" alt="" />
    </b-row>
    <b-row>
      <div class="mx-5 px-3 w-100" style="text-align: justify">
        <h4 v-html="$t('AboutUsTitle')"></h4>
        <p v-html="$t('AboutUsDesc')"></p>
        <br />
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "About",
  metaInfo: {
    title: "About Us",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Yamhad.net هي منصة فريدة للتجارة الإلكترونية تزود تجار التجزئة بسلع عالية الجودة مثل مستلزمات محلات الورود والزهور )مزهريات – شرائط – ورق تغليف – ورد صناعي – ورد طبيعي دائم – بوكسات - بالون( ومستلزمات محلات الهدايا والحفلات وغيرها",
      },
    ],
  },
};
</script>

<style>
</style>